@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './mapfixes.css';
@import './onetrust.css';

/* customize style from the electron design system's default "base" tailwind plugin */
@layer base {
  @font-face {
    font-family: 'Duke Energy';
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    src: url('../assets/fonts/duke-energy-regular.woff2') format('woff2'),
      url('../assets/fonts/duke-energy-regular.woff2') format('woff');
  }

  @font-face {
    font-family: 'Duke Energy';
    font-weight: 700;
    font-style: normal;
    font-display: auto;
    src: url('../assets/fonts/duke-energy-bold.woff2') format('woff2'),
      url('../assets/fonts/duke-energy-bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Roboto Flex';
    font-weight: 100 1000;
    font-style: normal;
    font-display: auto;
    src: url('../assets/fonts/roboto-flex.woff2') format('woff2-variations');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
      U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
      U+FFFD;
  }

  @font-face {
    font-family: 'NewsGothicBT-Roman';
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    src: url('../assets/fonts/roboto-flex.woff2') format('woff2-variations');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
      U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
      U+FFFD;
  }

  @font-face {
    font-family: 'NewsGothicBT-Light';
    font-weight: 200;
    font-style: normal;
    font-display: auto;
    src: url('../assets/fonts/roboto-flex.woff2') format('woff2-variations');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
      U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
      U+FFFD;
  }

  @font-face {
    font-family: 'NewsGothicBT-Bold';
    font-weight: 700;
    font-style: normal;
    font-display: auto;
    src: url('../assets/fonts/roboto-flex.woff2') format('woff2-variations');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
      U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
      U+FFFD;
  }

  @font-face {
    font-family: 'NewsGothicBT-Demi';
    font-weight: 700;
    font-style: normal;
    font-display: auto;
    src: url('../assets/fonts/roboto-flex.woff2') format('woff2-variations');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
      U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
      U+FFFD;
  }

  /* set defaults globally */
  body {
    font-weight: 400;
    font-family: 'Roboto Flex', Roboto, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
    font-family: 'Duke Energy', 'Roboto Flex', Roboto, -apple-system, BlinkMacSystemFont, Arial,
      sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  .text-balance {
    text-wrap: balance;
    white-space: unset;
  }

  html {
    scroll-behavior: auto;
  }
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Add asterisk to text displayed in form label if that input is required */
[required] ~ label:not(label.form-input-label, label.form-select-label):after,
[data-required='true'] > legend:after,
[data-required='true-single'] ul li label:after {
  content: '\2009*';
  letter-spacing: 0.125em;
}

/* 
  Additional img style not included electron tailwind rich-text plugin; 
  legacy RichText component layouts expect the img to display inline 
*/
.rich-text img {
  display: inline;
}

/* not currently included in electron tailwind */
.left-1\/2 {
  left: 50%;
}

/* Hero needs to be specific heights not in electron tailwind */
.customStyle-Hero {
  height: 29vmax;
}

/* 48em - screen 'md' */
@media (min-width: 48em) {
  .customStyle-Hero {
    height: 490px;
  }
}

/* Qualtrics "feedback slider" */
div.QSISlider div[id^='slider-control-'] {
  top: 283px !important;
  height: 126px !important;
}

/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData,
.scpm {
  display: none !important;
}

/* 
  Force content normally hidden behind :hover to display in Sitecore Experience Editor so it can be accessed by users
 */
.scShowHoverState {
  opacity: 1 !important;
  position: relative !important;
}

.form-input-field::-ms-reveal {
  display: none;
}

/* putting Sprinklr chatbot window behind session expiration modal */
.spr-chat__box.spr-live-chat-frame,
.css-xtumnj {
  z-index: 949 !important;
}

input.hide-webkit-clear[type='search']::-webkit-search-decoration,
input.hide-webkit-clear[type='search']::-webkit-search-cancel-button,
input.hide-webkit-clear[type='search']::-webkit-search-results-button,
input.hide-webkit-clear[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/* animations for find it duke find a contractor steps, need to use keyframes for start position since steps are dynamically added/removed  */
.step-left-animation {
  animation: stepLeft 500ms;
  animation-fill-mode: forwards;
}

.step-right-animation {
  animation: stepRight 500ms;
  animation-fill-mode: forwards;
}

.step-from-right-animation {
  animation: stepFromRight 500ms;
  animation-fill-mode: forwards;
}

.step-from-left-animation {
  animation: stepFromLeft 500ms;
  animation-fill-mode: forwards;
}

@keyframes stepLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes stepRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes stepFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes stepFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
/* end find it duke find a contractor animations */

/* start PNG customer style */
.png-background {
  background-color: #0074b3;
}

.png-text {
  color: #0074b3;
}

/* link intercept modal */
.link-intercept-modal {
  z-index: 1000;
}
